import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  list: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/plan/list`, {
      data,
    });
  },
  detail: async (id: number) => {
    return await http.get(`${getApiUrl()}/api/plan/detail/${id}`);
  },
  order: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/plan/order`, {
      data,
    });
  },
};

export default {
  ...actions,
};
